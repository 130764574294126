import { combineReducers } from 'redux';
import authreducer from '../reducers/auth';
import adminreducer from './admin';
// import categoriesReducer from './categories'
// import subcategoriesReducer from './sub_categories';
// import chapterReducer from './chapters'
// import contentReducer from './content'
// import subReducer from './subscription'
// import partnerReducer from './partner'
// import triggerReducer from './trigger'
const appReducer = combineReducers({
//     // user,
    authreducer: authreducer,
//     userReducer: userReducer,
    adminreducer: adminreducer,
    // categoriesReducer: categoriesReducer,
    // subcategoriesReducer: subcategoriesReducer,
//     subReducer: subReducer,
//     partnerReducer: partnerReducer,
//     triggerReducer: triggerReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
//auth
export const SHOW_LOADING = "SHOW_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";

//toast
export const SHOW_TOAST = "SHOW_TOAST";

//admin types
export const GET_ADMIN_DATA = "GET_ADMIN_DATA"
export const CHANGE_ADMIN_PASSWORD = "CHANGE_ADMIN_PASSWORD"
export const EDIT_ADMIN_DETAIL = "EDIT_ADMIN_DETAIL";

//user type
export const GET_USERS = "GET_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const PARTICULAR_USER_DATA = "PARTICULAR_USER_DATA";

//blog types
export const GET_BLOG_DATA = "GET_BLOG_DATA"
export const ADD_BLOG = "ADD_BLOG"
export const UPDATE_BLOG = "UPDATE_BLOG"
export const UPDATE_BLOG_STATUS = "UPDATE_BLOG_STATUS"

//category types
export const GET_CATEGORY_DATA = "GET_CATEGORY_DATA"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_STATUS = "UPDATE_CATEGORY_STATUS"
export const ADD_IMAGES = "ADD_IMAGES"

//program category types
export const GET_PROGRAM_CATEGORY_DATA = "GET_PROGRAM_CATEGORY_DATA"
export const ADD_PROGRAM_CATEGORY = "ADD_PROGRAM_CATEGORY"
export const UPDATE_PROGRAM_CATEGORY = "UPDATE_PROGRAM_CATEGORY"
export const UPDATE_PROGRAM_CATEGORY_STATUS = "UPDATE_PROGRAM_CATEGORY_STATUS"

//FAQ
export const GET_QUESTION_ANSWER = "GET_QUESTION_ANSWER"
export const ADD_FAQ = "ADD_FAQ"
export const DELETE_FAQ = "DELETE_FAQ"
export const UPDATE_FAQ = "UPDATE_FAQ"

//terms_conndition
export const GET_TERM_CONDITION = "GET_TERM_CONDITION"
export const UPDATE_TERM_CONDITION = "UPDATE_TERM_CONDITION"

//products
export const GET_PRODUCT_DATA = "GET_PRODUCT_DATA"
export const GET_COLORS_DATA = "GET_COLORS_DATA"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const EDIT_PRODUCT = "EDIT_PRODUCT"
export const EDIT_COLORS = "EDIT_COLORS"
export const DELETE_COLORS = "DELETE_COLORS"
export const DELETE_IMAGE = "DELETE_IMAGE"
export const ADD_COLORS = "ADD_COLORS"

//program
export const ADD_PROGRAM = "ADD_PROGRAM"
export const GET_PROGRAM_DATA = "GET_PROGRAM_DATA"
export const DELETE_PROGRAM = "DELETE_PROGRAM"
export const EDIT_PROGRAM = "EDIT_PROGRAM"
export const GET_INTRO_VIDEO = "GET_INTRO_VIDEO"
export const DELETE_VIDEO = "DELETE_VIDEO"
export const ADD_VIDEO = "ADD_VIDEO"
export const ADD_QUESTION = "ADD_QUESTION"
export const DELETE_QUESTION = "DELETE_QUESTION"
export const UPDATE_QUESTION = "UPDATE_QUESTION"
export const ADD_PROGRAM_CYCLE = "ADD_PROGRAM_CYCLE"
export const GET_PROGRAM_CYCLE_DATA = "GET_PROGRAM_CYCLE_DATA"

//workout
export const GET_WORKOUT = "GET_WORKOUT"
export const UPDATE_WORKOUT = "UPDATE_WORKOUT"
export const ADD_WORKOUT = "ADD_WORKOUT"
export const DELETE_WORKOUT = "DELETE_WORKOUT"
export const DRAG_WORKOUT = "DRAG_WORKOUT"

//session
export const ADD_SESSION = "ADD_SESSION"
export const GET_SESSION_DATA = "GET_SESSION_DATA"
export const UPDATE_SESSION = "UPDATE_SESSION"
export const DELETE_SESSION = "DELETE_SESSION"

//community
export const GET_COMMUNITY_DATA = "GET_COMMUNITY_DATA"
export const ADD_COMMUNITY = "ADD_COMMUNITY"
export const DELETE_POST = "DELETE_POST"

//getting started 
export const GET_STARTED_DATA = "GET_STARTED_DATA"
export const ADD_STARTED_VIDEO = "ADD_STARTED_VIDEO"
export const DELETE_STARTED_VIDEO = "DELETE_STARTED_VIDEO"
export const EDIT_STARTED_VIDEO = "EDIT_STARTED_VIDEO"
export const DRAG_VIDEO = "DRAG_VIDEO"

//dashbord data
export const DAHBOARD_DATA = "DAHBOARD_DATA"

//academy data
export const ACADEMY_DATA = "ACADEMY_DATA"

//view gallery
export const    GET_VIDEO_GALLERY = "GET_VIDEO_GALLERY"
export const    ADD_VIDEO_GALLERY = "ADD_VIDEO_GALLERY"

import React, { lazy, Suspense } from 'react'
import { HashRouter as Router } from 'react-router-dom';
import './App.css';
import { Provider } from "react-redux"
import store from "./store"

const AppRouter = lazy(() => import('./routes'))

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div className={'text-center mt-3'}>Loading Please Wait....</div>}>
          <AppRouter />
        </Suspense>
      </Router>
    </Provider>
  );
}
export default App;